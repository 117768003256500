import React, { Component } from 'react';
import "./work.scss";


class Work extends Component {
    state = {  } 
    render() { 
        return (<div className="work-page">


        

        <div className='container'> 
        
        <h1> Page is Under Construction</h1>
        <p> Currently working on the page</p>
        <p> Rebranding+Upgrade</p>
            
        </div> 
      

        


        </div>);
    }
}
 
export default Work;



