import React, { Component } from 'react';
import  './contact.scss'; 


class Contact extends Component {
    state = {  } 
    render() { 
        return (<div className="contact-page">
            
             

        <div className='container'> 
        
        <h1> Page is Under Construction</h1>
        <p> Currently working on the page</p>
        <p> Rebranding+Upgrade</p>
            
            <div className="box"> </div>
        </div> 
      

        </div>);
    }
}
 
export default Contact;