//import { Link } from 'react-router-dom'
import React, { Component } from 'react';
import "./about.scss"


class About extends Component {
    state = {  } 
    render() { 
        return (
        <div className="about-page">
         

         <div className='container'> 
        
        <h1> Page is Under Construction</h1>
        <p> Currently working on the page</p>
        <p> Rebranding+Upgrade</p>
            
        </div> 
      


        </div>);
    }
}
 
export default About;
