import "./home.scss"; 
import React, { Component } from 'react';



class Home extends Component {
    state = {  } 
    render() { 
        return ( 
        
        <div className="home-page"> 
            
            <h1> Home Works</h1>
        </div>);
    }
}
 
export default Home;