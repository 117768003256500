import React, { Component } from 'react';
import "./notFound.scss";



class NotFound extends Component {
    state = {  } 
    render() { 
        return (  
            <div className="NotFound"> 




            </div>
        );
    }
}
 
export default NotFound;
